<i18n locale="th" lang="yaml" >
delivery.out.shipping.shipping_agent.label : "ส่งผ่าน"
delivery.out.shipping.reference_no.label : "หมายเลขจัดส่ง"
delivery.out.self_shipping.shipping_by.label : "ส่งโดย"
delivery.out.self_shipping.receive_date.label : "ถึงลูกค้าวันที่"

delivery.in.self_shipping.request_date.label : "วันที่ลูกค้าแจ้ง"
delivery.in.self_shipping.shipping_by.label : "ไปรับโดย"
delivery.in.service_shipping.shipping_by.label : "ส่งมาโดย"
</i18n>

<template>
	<div class="delivery-container">
		<div class="delivery-type">
			{{$tenum(enumName,type)}}
		</div>
		<template v-if="isSendOut">
			<div v-if="type == 'shipping'" class="delivery-detail">
				<div class="delivery-col">
					<label>{{$t('delivery.out.shipping.shipping_agent.label')}}</label>
					<span class="delivery-value">
						{{deliveryData.shipping_agent | emptyCheck}}
					</span>
				</div>
				<div class="delivery-col">
					<label>{{$t('delivery.out.shipping.reference_no.label')}}</label>
					<span class="delivery-value">
						{{deliveryData.reference_no | emptyCheck}}
					</span>
				</div>
			</div>

			<div v-if="type == 'self_shipping'" class="delivery-detail">
				<div class="delivery-col">
					<label>{{$t('delivery.out.self_shipping.shipping_by.label')}}</label>
					<span class="delivery-value">
						{{deliveryData.shipping_by | emptyCheck}}
					</span>
				</div>
				<div class="delivery-col">
					<label>{{$t('delivery.out.self_shipping.receive_date.label')}}</label>
					<span class="delivery-value">
						{{deliveryData.receive_date | date('LL',$t('common.unspecify'))}}
					</span>
				</div>
			</div>
		</template>
		<template v-else>
			<div v-if="type == 'self_shipping'" class="delivery-detail">
				<div class="delivery-col">
					<label>{{$t('delivery.in.self_shipping.request_date.label')}}</label>
					<span class="delivery-value">
						{{deliveryData.request_date | date('LL',$t('common.unspecify'))}}
					</span>
				</div>
				<div class="delivery-col">
					<label>{{$t('delivery.in.self_shipping.shipping_by.label')}}</label>
					<span class="delivery-value">
						{{deliveryData.shipping_by|emptyCheck}}
					</span>
				</div>
			</div>
			<div v-if="type == 'service_shipping'" class="delivery-detail">
				<div class="delivery-col">
					<label>{{$t('delivery.in.service_shipping.shipping_by.label')}}</label>
					<span class="delivery-value">
						{{deliveryData.shipping_by|emptyCheck}}
					</span>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	props : {
		isSendOut : {
			type : Boolean,
			default : false
		} ,
		type : {
			type : String ,
			default : undefined,
		} ,
		deliveryData : {
			type : null,
			default : () => []
		}
	} ,
	computed : {
		enumName() {
			return this.isSendOut ? 'delivery.type.out' : 'delivery.type'
		} ,
	}
}
</script>

<style lang="less" scoped>
.delivery-type {
}
.delivery-detail {
	margin-top : 2px;
	margin-bottom: -8px;
	display : flex;
	flex-wrap: wrap;
	.delivery-col {
		margin : 0 16px 8px 0;
		label {
			color : @info-color;
			margin-right : 2px;
			&::after {
				content : ' :'
			}
		}
	}
	.delivery-value {
		color : @primary-color;
		text-decoration: underline dashed;
	}
}
</style>
