<template>
	<div class="image-with-name">
		<ImageLoader :src="src" :size="size" border-type="circle" >
			<slot>
				<a-icon type="file-image" />
			</slot>
		</ImageLoader>
		<span :class='cNameCssClass'>{{name}}</span>
	</div>
</template>
<script>
import ImageLoader from "@components/common/ImageLoader.vue"
import {isStringEmpty as _isStringEmpty} from "@utils/stringUtil"
export default {
	components : {
		ImageLoader,
	} ,
	props : {
		src : {
			type : String ,
			default: null
		} ,
		size : {
			type : String,
			default : 'tiny'
		} ,
		name : {
			type : String,
			default : ''
		} ,
		nameCssClass : {
			type : String,
			default : undefined
		} ,
	} ,
	computed : {
		cNameCssClass() {
			const css = _isStringEmpty(this.size) ? "image-text" : "image-text-"+this.size
			return [
				css ,
				this.nameCssClass
			]
		} ,
	}
}
</script>
