import {isStringEmpty,isVarEmpty} from "@utils/stringUtil"

export default class FormError extends Error {
	constructor(component,message = null){
		super(isStringEmpty(message) ? component.$t('common.error.validate_error') : message)
		this.component = component
		this.detailMessages = {}
		this.generalMessages = []
	}

	addMessage(modelKey,message,modelName = null) {
		modelName = (isStringEmpty(modelName)) ? this.component.$t(modelKey) : modelName
		if (modelKey in this.detailMessages) {
			this.detailMessages[modelKey].errors.push(message)
		} else {
			this.detailMessages[modelKey] = {
				key : modelKey,
				name : modelName,
				errors : [message]
			}
		}
	}

	addGeneralMessage(message) {
		this.generalMessages.push(message)
	}

	hasErrors() {
		return this.generalMessages.length > 0 || !isVarEmpty(this.detailMessages)
	}

}
