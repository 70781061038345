<template>
	<div class="image-list-container">
		<div class="image-list">
			<div v-for="(file,index) in cfileMedias"
				:key="file.key" class="image-list-image">
				<ImageLoader
					:src="file.thumb"
					:size="size"
					@click="clickImage($event,file,index)"/>
			</div>
		</div>
		<LightBox
			v-if="hasLightBox"
			ref="lightbox"
			:show-light-box="false"
			:show-caption="true"
			:media="cfileMedias"/>
	</div>
</template>

<script>
import LightBox from "vue-image-lightbox"
import ImageLoader from "@components/common/ImageLoader.vue"

function convertContentType(type) {
	if (!!type && type.indexOf('video/') === 0) {
		return 'video'
	} else if (!!type && type.indexOf('image/') === 0) {
		return 'image'
	} else {
		return undefined
	}
}

export default {
	components : {
		LightBox ,ImageLoader ,
	} ,
	props : {
		fileList : {
			type : Array,
			default : () => []
		} ,
		hasOwnLightBox : {
			type: Boolean,
			default : false,
		} ,
		size : {
			type : String,
			default : null,
		}
	} ,
	computed : {
		hasLightBox() {
			return this.hasOwnLightBox && this.fileList.length > 0
		} ,
		cfileMedias() {
			return this.fileList.filter((file)=>{
				// require field
				return ('file_url' in file) && ('content_type' in file) && convertContentType(file.content_type)
			}).map((file)=>{
				const type = convertContentType(file.content_type);
				const caption = ('filename' in file) ? file.filename : file.file_url
				if (type == 'video') {
					// video
					return {
						key : file.file_url ,
						thumb : require("@assets/images/file_video.png") ,
						sources : [
							{
								src : file.file_url ,
								type : file.content_type
							}
						],
						caption ,
						type : 'video',
						autoplay : false,
					}
				} else {
					// image
					return {
						key : file.file_url ,
						thumb : file.file_url ,
						src : file.file_url ,
						caption : caption
					}
				}
			})
		} ,
	} ,
	methods : {
		clickImage(event,file,index) {
			if (this.$refs.lightbox) {
				this.$refs.lightbox.showImage(index);
			} else {
				this.$emit('click',{file,index,mediaList : this.cfileMedias})
			}
		}
	}
}
</script>

<style lang="less">
.image-list {
	display : flex;
	flex-wrap : wrap;
}
.image-list-image {
	padding : 8px;
	margin : 0 4px 8px 0;
	cursor : pointer;
	background : @white;
	border : 1px dashed @border-color-base;
	border-radius: @border-radius-base;
	.image-loader {
		background : transparent;
	}
}
</style>
