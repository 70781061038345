<template>
	<a-popover
		:get-popup-container="getPopupContainer"
		:overlay-class-name="cOverlayClassName"
		class="mypopover-trigger"
		:trigger="trigger"
		v-bind="$attrs"
		v-on="$listeners">
		<slot v-for="(_, name) in $slots" :slot="name" :name="name" />
	</a-popover>
</template>

<script>
import {Popover} from "ant-design-vue"
import PopupMixin from "@mixins/PopupMixin.vue"
export default {
	components : {
		"a-popover" : Popover,
	} ,
	mixins : [PopupMixin] ,
	props : {
		overlayClassName : {
			type : String,
			default : undefined
		} ,
		trigger :{
			type : String,
			default : 'click|hover'
		}
	} ,
	computed : {
		cOverlayClassName() {
			let rtn = 'mypopover';
			if (this.$notEmpty(this.overlayClassName)) {
				rtn += ' '+this.overlayClassName;
			}
			return rtn;
		}
	} ,
}
</script>
