<i18n locale="th" lang="yaml" >
service_action.label : "จัดการงานซ่อม"
service_action.update.title : "แก้ไขใบงาน"
service_action.link_ticket.title : "Ticket ที่เกี่ยวข้อง"

service_action.claim_request.title : "ขอเปิดเคลม"
service_action.claim_request.confirm.title : "ขอเปิดเคลม"
service_action.claim_request.confirm.message : "คุณต้องการเปิดใบเคลมสำหรับงานนี้ ?"

service_action.start.title : "เริ่มซ่อม"
service_action.job_done.title : "ซ่อมเสร็จ ทดสอบก่อนคืน"
service_action.test_done.title : "ทดสอบเสร็จ รอคืน"
service_action.rework.title : "พบปัญหา ซ่อมใหม่"
service_action.rework_pending.title : "เริ่มซ่อมต่อ"
service_action.pending.title : "รอซ่อม (Pending)"
service_action.all_done.title : "ส่งคืนเรียบร้อย ปิดงาน"
service_action.cancel.title : "ยกเลิกงาน"

service_action.has_change.title : "มีการแก้ไขข้อมูล"
service_action.has_change.message : "ต้องบันทึกข้อมูลก่อนเปลี่ยนสถานะ หรือ ยกเลิกการแก้ไขก่อน"

service_action.start.confirm.title : "เริ่มซ่อมใบงานนี้"
service_action.start.confirm.message : "คุณต้องการเปลี่ยนสถานะใบงานนี้ ให้เป็นเริ่มการซ่อม ?"
service_action.job_done.confirm.title : "ซ่อมเสร็จแล้ว"
service_action.job_done.confirm.message : "คุณต้องการเปลี่ยนสถานะใบงานนี้ ให้เริ่มทดสอบการส่งคืน ?"
service_action.test_done.confirm.title : "ทดสอบเสร็จสิ้น"
service_action.test_done.confirm.message : "คุณต้องการเปลี่ยนสถานะใบงานนี้ พร้อมส่งคืนลูกค้า ?"
service_action.rework_pending.confirm.title : "เริ่มซ่อมต่อ"
service_action.rework_pending.confirm.message : "คุณต้องการเปลี่ยนสถานะใบงานนี้ ว่าพร้อมซ่อมต่อแล้ว ?"

service_action.all_done.confirm.title : "ส่งคืนลูกค้าเรียบร้อย"
service_action.all_done.confirm.message : "คุณต้องการเปลี่ยนสถานะใบงานนี้ ว่าลูกค้ารับคืนไปเรียบร้อยแล้ว ?"

service_action.pending.confirm.title : "งานรอซ่อม"
service_action.pending.confirm.message : "คุณต้องการเปลี่ยนสถานะใบงานนี้ ให้เป็นการรอซ่อม ?"
service_action.pending.reasons.1 : "รออะไหล่"
service_action.pending.reasons.2 : "รอวิธีแก้ไขจากทาง HGR"
service_action.pending.reasons.3 : "หาสาเหตุยังไม่เจอ"

service_action.rework.confirm.title : "นำกลับมาซ่อมใหม่"
service_action.rework.confirm.message : "คุณต้องการเปลี่ยนสถานะใบงานนี้ ให้นำกลับมาซ่อมใหม่ ?"
service_action.rework.reasons.1 : "พบปัญหาระหว่างทดสอบ"
service_action.rework.reasons.2 : "ซ่อมไม่ครบ"
service_action.rework.reasons.3 : "พบปัญหาอื่นเพิ่ม"
service_action.rework.reasons.4 : "ลูกค้าต้องการปรับเปลี่ยนอุปกรณ์บางอย่าง"

service_action.cancel.confirm.title : "ยกเลิกงาน"
service_action.cancel.confirm.message : "คุณต้องการยกเลิกงานซ่อมนี้ ?"
service_action.cancel.reasons.1 : "กรอกข้อมูลผิดพลาด"
service_action.cancel.reasons.2 : "ใบงานซ้ำ"
service_action.cancel.reasons.3 : "ลูกค้าต้องการนำเครื่องไปใช้งานก่อน"

service_action.field.reason : "สาเหตุ"
</i18n>

<template>
	<div class="service-action-pane ">
		<a-dropdown v-if="service.id > 0 && canManageService">
			<a-button class="btn-page-action"> {{$t('service_action.label')}} <a-icon type="down" /> </a-button>
			<a-menu slot="overlay" class="service-action-menu" @click="handleMenuSelect">
				<a-menu-item v-if="canUpdate" key="update">
					<a-icon type="edit" /> {{$t('service_action.update.title')}}
				</a-menu-item>
				<a-menu-item key="link_ticket" :disabled="!canLinkTicket">
					<a-icon type="link" /> {{$t('service_action.link_ticket.title')}}
				</a-menu-item>
				<a-menu-item v-show="showClaimRequest" key="claim_request" :disabled="!canClaimRequest">
					<a-icon type="flag" /> {{$t('service_action.claim_request.title')}}
				</a-menu-item>
				<a-menu-divider v-if="canUpdate"  key="divider_update"/>
				<a-menu-item v-if="canStart" key="start">
					<ServiceIcon class="anticon" /> {{$t('service_action.start.title')}}
				</a-menu-item>
				<a-menu-item v-if="canJobDone" key="job_done">
					<PreDeliveryIcon class="anticon" /> {{$t('service_action.job_done.title')}}
				</a-menu-item>
				<a-menu-item v-if="canTestDone" key="test_done">
					<DeliveryIcon class="anticon" /> {{$t('service_action.test_done.title')}}
				</a-menu-item>
				<a-menu-item v-if="canAllDone" key="all_done" class="text-success">
					<a-icon type="check-circle" /> {{$t('service_action.all_done.title')}}
				</a-menu-item>
				<a-menu-divider v-if="canUpdate" key="divider_abnormal"/>
				<a-menu-item v-if="canPending" key="pending">
					<PendingIcon class="anticon" /> {{$t('service_action.pending.title')}}
				</a-menu-item>
				<a-menu-item v-if="canReworkPending" key="rework_pending">
					<ServiceIcon class="anticon" /> {{$t('service_action.rework_pending.title')}}
				</a-menu-item>
				<a-menu-item v-if="canRework" key="rework" class="text-warning">
					<ServiceIcon class="anticon" /> {{$t('service_action.rework.title')}}
				</a-menu-item>
				<a-menu-item v-if="canCancel" key="cancel" class="text-error">
					<a-icon type="close-circle" /> {{$t('service_action.cancel.title')}}
				</a-menu-item>
			</a-menu>
		</a-dropdown>
		<MyConfirmReasonModel
			ref="reasonModalRef"
			:title="selectedMenu.title"
			:message="selectedMenu.message"
			:reasons="selectedMenu.reasons"
			:reason-label="$t('service_action.field.reason')"
			:confirm-again="true"
			@cancel="handleModalCancel"
			@confirm="handleModalConfirm">
			<component :is="selectedMenu.icon" slot="icon" :class="selectedMenu.iconCss" v-bind="selectedMenu.iconProps"/>
		</MyConfirmReasonModel>

		<ServiceLinkTicketModal
			v-if="canLinkTicket"
			ref="linkModalRef"
			:service="service"
			:can-open-new="true"
			@change="handleChangeTickets"/>
	</div>
</template>

<script>
import {Menu,Dropdown,Icon} from "ant-design-vue"
import MyConfirmReasonModel from "@components/input/MyConfirmReasonModal.vue"
import ServiceLinkTicketModal from "@components/service/ServiceLinkTicketModal.vue"
import ServiceIcon from "vue-material-design-icons/HammerScrewdriver.vue"
import PreDeliveryIcon from "vue-material-design-icons/OrderBoolAscendingVariant.vue"
import DeliveryIcon from "vue-material-design-icons/PackageVariantClosed.vue"
import PendingIcon from "vue-material-design-icons/PauseCircleOutline.vue"
import PageMixin from "@mixins/PageMixin.vue"
import axios from "axios"
import ServiceObjectMixin from '@mixins/service/ServiceObjectMixin.vue'
import LoginInfoMixin from '@/src/mixins/LoginInfoMixin.vue'
import {technician} from "@utils/objectUtil"

export default {
	components : {
		"a-menu" : Menu, "a-menu-item" : Menu.Item,
		"a-menu-divider" : Menu.Divider ,
		"a-dropdown" : Dropdown ,
		ServiceIcon,PreDeliveryIcon,DeliveryIcon, PendingIcon ,
		ServiceLinkTicketModal, MyConfirmReasonModel,
	} ,
	mixins : [PageMixin,ServiceObjectMixin,LoginInfoMixin] ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		otherDetails : {
			type : null,
			default : () => []
		} ,
		linkTickets : {
			type : null,
			default : () => []
		} ,
		checkPreventExit : {
			type : Function,
			default : undefined
		}
	} ,
	data() {
		return {
			selectedMenu : {} ,
			selectedStatus : undefined,
		}
	} ,
	computed : {
		reasonStatusList() {
			return {
				'rework' : {
					title : this.$t('service_action.rework.confirm.title') ,
					message : this.$t('service_action.rework.confirm.message') ,
					icon : ServiceIcon ,
					iconCss : 'text-warning' ,
					reasons : [
						this.$t('service_action.rework.reasons.1') ,
						this.$t('service_action.rework.reasons.2') ,
						this.$t('service_action.rework.reasons.3') ,
						this.$t('service_action.rework.reasons.4') ,
					]
				} ,
				'pending' : {
					title : this.$t('service_action.pending.confirm.title') ,
					message : this.$t('service_action.pending.confirm.message') ,
					icon : PendingIcon ,
					iconCss : 'text-muted' ,
					reasons : [
						this.$t('service_action.pending.reasons.1') ,
						this.$t('service_action.pending.reasons.2') ,
						this.$t('service_action.pending.reasons.3') ,
					]
				} ,
				'cancel' : {
					title : this.$t('service_action.cancel.confirm.title') ,
					message : this.$t('service_action.cancel.confirm.message') ,
					icon : Icon,
					iconProps : {type : 'close-circle'} ,
					iconCss : 'text-error' ,
					reasons : [
						this.$t('service_action.cancel.reasons.1') ,
						this.$t('service_action.cancel.reasons.2') ,
						this.$t('service_action.cancel.reasons.3') ,
					]
				}
			}
		} ,
		isValid() {
			return this.service.id && this.service.id > 0
		} ,
		showClaimRequest() {
			return this.canServiceClaim &&
				this.$authorize('create','claim')
		} ,
		canClaimRequest() {
			return this.showClaimRequest &&
				!('claim_request' in this.otherDetails)
		} ,
		canStart() {
			return this.isValid && this.service.status == 'new'
		} ,
		canJobDone() {
			return this.isValid && this.service.status == 'in_progress'
		} ,
		canPending() {
			return this.isValid && this.service.status == 'in_progress'
		} ,
		canReworkPending() {
			return this.isValid && this.service.status == 'pending'
		} ,
		canRework() {
			return this.isValid && (this.service.status == 'pre_delivery' || this.service.status == 'wait_delivery')
		} ,
		canTestDone() {
			return this.isValid && this.service.status == 'pre_delivery'
		} ,
		canAllDone() {
			return this.isValid && this.service.status == 'wait_delivery'
		} ,
		canCancel() {
			return this.isValid && !(this.service.status == 'cancel'  || this.service.status == 'close' || this.service.status == 'in_progress' || this.service.status == 'pre_delivery' || this.service.status == 'wait_delivery')
		} ,
		canOpenTicket() {
			return this.isValid && !(this.service.status == 'cancel')
		} ,
		canLinkTicket() {
			return this.canOpenTicket
		} ,
		canManageService() {
			return this.currentUser.user_role !== technician
		}
	} ,
	methods : {
		handleMenuSelect({key}) {
			switch (key) {
				case 'update' :
					this.$open({name : 'service/update',params : {id : this.service.id,from : this.$route.fullPath}})
					break;
				case 'link_ticket' :
					if (this.$refs.linkModalRef) {
						this.$refs.linkModalRef.openModal()
					}
					break
				case 'claim_request' :
					if (this.checkPreventExit && this.checkPreventExit()) {
						// Ask save before
						this.$error({
							title : this.$t('service_action.has_change.title') ,
							content : this.$t('service_action.has_change.message')
						})
					} else {
						this.$confirm({
							title : this.$t('service_action.claim_request.confirm.title') ,
							content : this.$t('service_action.claim_request.confirm.message') ,
							okText : this.$t('common.confirm') ,
							maskClosable : true,
							onOk: ()=> {
								this.showPageLoading(true)
								axios.post("/api/services/"+this.service.id+"/claim-request/new").then((response)=>{
									this.$emit('error',undefined)
									this.$open({name : 'service/claim',params : {id :this.service.id}},'_self',true)
								}).catch((error)=>{
									this.$emit('error',error)
								}).finally(()=> {
									this.hidePageLoading()
								})
							} ,
						})
					}
					break
				case 'start' :
					this.callUpdateStatus('in_progress',key)
					break;
				case 'pending' :
					this.callUpdateStatus('pending',key)
					break;
				case 'job_done' :
					this.callUpdateStatus('pre_delivery',key)
					break;
				case 'test_done' :
					this.callUpdateStatus('wait_delivery',key)
					break;
				case 'rework_pending' :
					this.callUpdateStatus('in_progress',key)
					break;
				case 'rework' :
					this.callUpdateStatus('in_progress',key)
					break;
				case 'all_done' :
					this.callUpdateStatus('close',key)
					break;
				case 'cancel' :
					this.callUpdateStatus('cancel',key)
					break;
			}
		} ,
		callUpdateStatus(status,key) {
			if (this.checkPreventExit && this.checkPreventExit()) {
				// Ask save before
				this.$error({
					title : this.$t('service_action.has_change.title') ,
					content : this.$t('service_action.has_change.message')
				})
			} else {
				if (key in this.reasonStatusList) {
					this.selectedMenu = this.reasonStatusList[key]
					this.selectedStatus = status
					this.$nextTick(() => {
						this.$refs.reasonModalRef.show()
					})
				} else {
					this.$confirm({
						title : this.$t('service_action.'+key+'.confirm.title') ,
						content : this.$t('service_action.'+key+'.confirm.message') ,
						okText : this.$t('common.confirm') ,
						maskClosable : true,
						onOk: ()=> {
							axios.post("/api/services/"+this.service.id+"/update-status/"+status).then((response)=>{
								this.$emit('error',undefined)
								this.$open({name : 'service/view',params : {id :this.service.id}},'_self',true)
							}).catch((error)=>{
								this.$emit('error',error)
							})
						} ,
					})
				}
			}
		} ,
		handleChangeTickets(payload) {
			this.$emit("changeLinkTickets",payload)
		} ,
		handleModalConfirm(payload) {
			this.showPageLoading(true)

			axios.post("/api/services/"+this.service.id+"/update-status/"+this.selectedStatus,{reason : payload.reason}).then((response)=>{
				this.$emit('error',undefined)
				this.$open({name : 'service/view',params : {id :this.service.id}},'_self',true)
			}).catch((error)=>{
				this.$emit('error',error)
			}).finally(() => {
				this.hidePageLoading()
				if (this.$refs.reasonModalRef)
					this.$refs.reasonModalRef.hide()
			})
		} ,
		handleModalCancel() {
			this.selectedMenu = {}
			this.selectedStatus = undefined
		}
	}
}
</script>

<style lang="less" scoped>
.service-action-pane {
	display : inline-block;
	width : auto;
}
</style>
