<i18n locale="th" lang="yaml" >
reason.label.default : "สาเหตุ"
confirm.message.default : "คุณต้องการยืนยันการเปลี่ยนแปลงนี้ ?"
</i18n>

<template>
	<a-modal
		:visible="visible"
		:title="title"
		:ok-text="$t('common.confirm')"
		:cancel-text="$t('common.cancel')"
		:ok-button-props="cModalOkProps"
		@ok="handleModalConfirm"
		@cancel="handleModalCancel">
		<div class="action-modal-content">
			<span class="icon">
				<slot name="icon"/>
			</span>
			<p v-if="!hasMessageSlot">
				{{message}}
			</p>
			<p v-else>
				<slot />
			</p>
		</div>

		<div class="action-modal-form-item">
			<label>{{cReasonLabel}}</label>
			<a-auto-complete
				v-model="reason"
				:data-source="reasons"
				allow-clear
				auto-focus>
			</a-auto-complete>
		</div>
	</a-modal>
</template>

<script>
import {isStringEmpty as _isStringEmpty} from "@utils/stringUtil"
import {AutoComplete} from "ant-design-vue"
export default {
	components : {
		"a-auto-complete" : AutoComplete,
	} ,
	props : {
		title : {
			type : String,
			default : undefined,
		} ,
		message : {
			type : String,
			default : undefined,
		} ,
		reasonLabel : {
			type : String,
			default : undefined,
		} ,
		reasons : {
			type : null,
			default : () => []
		} ,
		requiredReason : {
			type : Boolean,
			default : true,
		} ,
		confirmAgain : {
			type : Boolean,
			default : false,
		} ,
		confirmAgainMessage : {
			type : String,
			default : undefined,
		}
	} ,
	data() {
		return {
			visible : false,
			reason : undefined,
		}
	} ,
	computed : {
		hasMessageSlot() {
			return !!this.$slots.default
		} ,
		cModalOkProps() {
			if (!this.requiredReason) {
				return {}
			} else {
				return {
					props : {
						disabled : _isStringEmpty(this.reason)
					}
				}
			}
		} ,
		cReasonLabel() {
			return _isStringEmpty(this.reasonLabel) ? this.$t('reason.label.default') : this.reasonLabel
		}
	} ,
	methods : {
		show() {
			this.visible = true
		} ,
		hide() {
			this.reason = undefined
			this.visible = false
			this.$nextTick(() => {
				this.$emit('cancel',{
					reason : this.reason
				})
			})
		} ,
 		handleModalConfirm() {
			if (this.requiredReason && _isStringEmpty(this.reason))
				return
			if (this.confirmAgain) {
				const confirmMessage = _isStringEmpty(this.confirmAgainMessage) ? (_isStringEmpty(this.message) ? this.$t('confirm.message.default') : this.message) : this.confirmAgainMessage
				this.$confirm({
					title : this.title,
					content : confirmMessage ,
					okText : this.$t('common.confirm') ,
					cancelText : this.$t('common.cancel') ,
					maskClosable : true,
					onOk: ()=> {
						this.$emit('confirm',{
							reason : this.reason
						})
					}
				});
			} else {
				this.$emit('confirm',{
					reason : this.reason
				})
			}
		} ,
		handleModalCancel() {
			this.hide()

		}
	}
}
</script>

<style lang="less" scoped>
.action-modal-content::v-deep {
	display : flex;
	align-items: center;
	.icon {
		> .material-design-icon {
			.material-icon-size(2.5em);
			margin-right : 18px;
		}
		> .anticon {
			font-size : 2.5em;
			margin-right : 18px;
		}
	}
	p {
		margin-bottom : 0;
	}

}
.action-modal-form-item {
	margin-top : 24px;
	margin-bottom : 12px;
	label {
		display : block;
		color : @label-color;
	}
	.ant-select-auto-complete {
		display : block;
		width: 100%;
	}
}
</style>
