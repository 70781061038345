<template>
	<div :class="cssClasses">
		<div class="comment-info">
			<my-router-link
				name="user/view" :param-value="user.id" :new-window="true" :clickable="linkClickable">
				<ImageName :name="user.username" :src="user.image_url">
					<a-icon type="user" />
				</ImageName>
			</my-router-link>
			<span class="text-muted image-text-tiny" :style="{ margin: '0 4px' }">
				{{$t('common.from')}}
			</span>
			<my-router-link name="company/view" :param-value="company.id" auth-action="read" auth-module="company"
			 :new-window="true" :clickable="linkClickable">
				<ImageName :name="company.name" :src="company.image_url">
					<a-icon type="shop" />
				</ImageName>
			</my-router-link>
			<DayFromNowText
				class="comment-info-time image-text-tiny"
				:prefix="$t('common.since')"
				:value="comment.created_datetime" />
		</div>

		<QuillViewer class="comment-content" :content="comment.detail_rich" />
		<ImageVideoList
			class="comment-images"
			:file-list="comment.galleries"
			@click="handleGalleryClick"/>
		<TicketCommentFileList
			:ticket="ticket"
			:file-list="comment.files"/>
	</div>
</template>

<script>
import ImageName from "@components/common/ImageName.vue"
import ImageVideoList from "@components/common/ImageVideoList.vue"
import QuillViewer from "@components/common/QuillViewer.vue"
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"
import DayFromNowText from "@components/common/DayFromNowText.vue"
import TicketCommentFileList from "@components/ticket/TicketCommentFileList.vue"

export default {
	components : {
		ImageName, ImageVideoList ,QuillViewer ,DayFromNowText, TicketCommentFileList
	} ,
	mixins : [
		LoginInfoMixin
	] ,
	props : {
		ticket : {
			type : null,
			default : () => []
		} ,
		comment : {
			type : null,
			default : ()=>[]
		} ,
		user : {
			type : null,
			default : ()=>[]
		} ,
		company : {
			type : null,
			default : ()=>[]
		} ,
		linkClickable :{
			type : Boolean,
			default : true,
		} ,
	} ,
	computed : {
		cssClasses() {
			return [
				"comment-box" ,
				{ "comment-box-own" : this.isOwn }
			]
		} ,
		isOwn() {
			return this.user.id && this.currentUser.id == this.user.id
		}
	} ,
	methods : {
		handleGalleryClick(event) {
			this.$emit('click-gallery',{
				comment : this.comment,
				event
			})
		}
	}
}
</script>

<style lang="less" scoped>
.comment-box {
	border-radius : @border-radius-base;
	padding : 12px 16px;
	margin-bottom : 24px;
	background-color : white;
	max-width : 540px;
	box-shadow: @shadow-2;
}
.comment-box-own {
	border : 1px dashed @info-color;
	box-shadow: none;
}
.comment-info {
	margin-bottom : 8px;
}
.comment-info-time {
	color : @text-muted;
	font-size : 0.85em;
	margin-left : 16px;
}
.comment-content {
	padding: 0 0 12px 24px;
	margin-bottom: -1em;
}
.comment-images {
	margin-top : 8px;
}
</style>
