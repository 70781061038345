import { render, staticRenderFns } from "./TicketDetail.vue?vue&type=template&id=57f3240c&scoped=true"
import script from "./TicketDetail.vue?vue&type=script&lang=js"
export * from "./TicketDetail.vue?vue&type=script&lang=js"
import style0 from "./TicketDetail.vue?vue&type=style&index=0&id=57f3240c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57f3240c",
  null
  
)

/* custom blocks */
import block0 from "@i18n/ticket/ticket.th.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fcomponents%2Fticket%2FTicketDetail.vue&locale=th&lang=yaml&external"
if (typeof block0 === 'function') block0(component)
import block1 from "./TicketDetail.vue?vue&type=custom&index=1&blockType=i18n&locale=th&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports