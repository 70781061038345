<i18n locale="th" lang="yaml" >
service.cancel.title : "รายการถูกยกเลิก"
service.cancel.description : "ถูกยกเลิกโดย {name} เมื่อ {time}"
service.cancel.status_reason : "เนื่องจาก : {reason}"
</i18n>

<template>
	<div :class="containerCss">
		<div v-if="service.status == 'cancel'" class="service-progress-cancel">
			<div class="title">
				{{$t('service.cancel.title')}}
				<span v-if="$notEmpty(cancelLog.status_reason)">
					<br /> {{$t('service.cancel.status_reason',{ reason: cancelLog.status_reason })}}
				</span>
			</div>
			<div v-if="cancelLog.id" class="description">
				{{$t('service.cancel.description',{ name: cancelLog.updatedBy.user.username,time: $dayjs(cancelLog.updated_datetime).format('LLL') })}}
			</div>
		</div>
		<a-steps :current="currentStep" class="service-progress-step">
			<MyPopover slot="progressDot" slot-scope="{ index, prefixCls }">
				<template slot="content">
					<ServiceStatusLogCard :log="getLog(index)" :service="service" />
				</template>
				<span :class="`${prefixCls}-icon-dot`" />
			</MyPopover>
			<a-step v-for="item of stepItems" :key="item"
				:title="$tenum('service.status',item)"
				:description="$t('enum.service.status.' + item + '.description')">

			</a-step>
		</a-steps>
	</div>
</template>

<script>
import {Steps} from "ant-design-vue"
import MyPopover from "@components/common/MyPopover.vue"
import axios from "axios"
import PageMixin from "@mixins/PageMixin.vue"
import {fulfullServiceStatusLog} from "@utils/objectUtil"
import ServiceStatusLogCard from './ServiceStatusLogCard.vue'

export default {
	components : {
		"a-steps" : Steps, "a-step" : Steps.Step,
		MyPopover, ServiceStatusLogCard
	} ,
	mixins : [PageMixin] ,
	props : {
		service : {
			type : null,
			default : ()=>[]
		}
	} ,
	data() {
		return {
			loading : false,
			logs : [] ,

			currentServiceId : undefined,
			cancelLog : {},
		}
	} ,

	computed : {
		isCancel() {
			return this.service.id && this.service.status == 'cancel'
		} ,
		stepItems() {
			if (this.service.status == 'pending' || (this.isCancel && this.cancelLog.before_status == 'pending')) {
				return [
					'new','in_progress','pending','pre_delivery','wait_delivery','close'
				]
			} else {
				return [
					'new','in_progress','pre_delivery','wait_delivery','close'
				]
			}

		} ,
		currentStep() {
			if (this.service.id) {
				if (!this.isCancel) {
					return this.stepItems.indexOf(this.service.status)
				} else if (this.$notEmpty(this.cancelLog.before_status)) {
					return this.stepItems.indexOf(this.cancelLog.before_status)
				}
			}
			return -1
		} ,
		containerCss() {
			return [
				'service-progress-container' ,
				{
					'cancel' : this.isCancel
				}
			]
		}
	} ,
	watch : {
		$route(newVal) {
			if (newVal.params.id != this.currentServiceId) {
				this.fetchLogs()
			}
		}
	} ,
	mounted() {
		this.fetchLogs()
	} ,
	methods: {
		fetchLogs() {
			this.currentServiceId = this.$route.params.id
			if (!this.currentServiceId) {
				return
			}
			this.loading = true
			axios.get("/api/services/"+this.currentServiceId+"/logs").then((response) => {
				const logList = response.data.data.logs
				const bucket = response.data.data.bucket
				this.logs = {}
				this.cancelLog = {}
				for (const key in logList) {
					this.logs[key] = fulfullServiceStatusLog(logList[key],bucket)
					if (this.logs[key].status == 'cancel') {
						this.cancelLog = fulfullServiceStatusLog(logList[key],bucket)
					}
				}
			}).catch((error)=>{
				this.fetchError(error)
			}).finally(()=> {
				this.loading = false
			})
		} ,
		getLog(index) {
			if (index >=0 && index < this.stepItems.length) {
				const status = this.stepItems[index]
				if (status in this.logs) {
					return this.logs[status]
				}
			}
			return fulfullServiceStatusLog({},{})
		}
	}

}
</script>

<style lang="less" scoped>
.service-progress-container {
	padding : 24px 24px 16px;
	background : @white;
	box-shadow: @shadow-1-right;
	margin : 24px 0;

	.mobile & {
		padding : 16px 0;
	}
	.tablet & {
		padding : 16px 0;
	}
	&.cancel {
		background-image: repeating-linear-gradient(45deg,
			@gray-3 ,
			@gray-3 20px,
			transparent 20px,
			transparent 35px,
		);
	}
	@media(max-width:@screen-mobile) {
		display : none;
	}
}

.service-progress-cancel {
	font-family : @font-family-title;
	text-align : center;
	padding : 16px;
	margin-bottom : 12px;
	.title {
		font-size : 1.5em;
		color : @error-color;
	}
	.description {
		font-size : 0.9em;
		color : @text-muted;
	}
}

.service-progress-step::v-deep {
	.ant-steps-item-title {
		font-size : 14px;
	}
	.ant-steps-item-description {
		font-size : 12px;
	}
	.tablet & {
		.ant-steps-item-title {
			font-size : 13px;
		}
		.ant-steps-item-content {
			width : 110px;
		}
		.ant-steps-item-tail {
			margin-left : 55px;
		}
		.ant-steps-item-icon {
			margin-left : 52px;
		}
	}
	.mobile & {
		.ant-steps-item-title {
			font-size : 12px;
		}
		.ant-steps-item-description {
			display : none;
		}
		.ant-steps-item-content {
			width : 90px;
		}
		.ant-steps-item-tail {
			margin-left : 45px;
		}
		.ant-steps-item-icon {
			margin-left : 42px;
		}
	}
}
</style>
