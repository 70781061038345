<i18n locale="th" lang="yaml" >
comment.more.label : "แนบข้อมูลเพิ่ม"
comment.gallery.title : "แนบไฟล์รูปภาพ หรือ วิดีโอ (ขนาดไม่เกิน 40 MB)"
comment.files.title : "แนบ Log ไฟล์ (.bin) (ขนาดไม่เกิน 40 MB)"
</i18n>

<template>
	<div class="comment-input-container">
		<RichTextInput
			ref="richTextRef"
			v-model="content"
			@changeText="onEditorChange"/>
		<a class="comment-input-more" @click="handleMore">
			{{$t('comment.more.label')}} <a-icon :type="showMore ? 'up' : 'down'" />
		</a>
		<div v-show="showMore" class="comment-input-data">
			<div class="comment-input-gallery">
				<div class="title">{{$t('comment.gallery.title')}}</div>
				<ImageVideoUploader
					ref="imageUploader"
					module="ticket"
					:max-image-height="2048" :max-image-width="2048"
					@change="onGalleryChange"/>
			</div>
			<div class="comment-input-files">
				<div class="title">{{$t('comment.files.title')}}</div>
				<FilesUploader
					ref="fileUploader"
					module="ticket"
					:check-support-file="checkTicketFile"
					@change="onFilesChange" />
			</div>
		</div>

	</div>
</template>

<script>
import RichTextInput from "@components/input/RichTextInput.vue"
import ImageVideoUploader from "@components/input/ImageVideoUploader.vue"
import FilesUploader from "@components/input/FilesUploader.vue"
import { isStringEmpty } from '@utils/stringUtil'
export default {
	components : {
		ImageVideoUploader , FilesUploader, RichTextInput
	} ,
	data() {
		return {
			content : '',
			content_text : '',
			files : [] ,
			gallery : [] ,
			showMore : false,
		}
	} ,
	methods: {
		updateComment(comment) {
			this.clear()
			if (this.$refs.richTextRef) {
				this.$refs.richTextRef.setContent(comment.detail_rich)
			} else {
				this.content = comment.detail_rich
			}
			this.content_text = comment.detail_rich
		} ,
		onEditorChange(text) {
			this.content_text = text;
			this.emitChange('detail')
		} ,
		onGalleryChange(data) {
			this.gallery = data.files.map((file) => file.file_key)
			this.emitChange('gallery')
		} ,
		onFilesChange(data) {
			this.files = data.files.map((file) => file.file_key)
			this.emitChange('files')
		} ,
		emitChange(type) {
			this.$emit('change',{
				type,
				data : {
					detail : this.content,
					detail_text : this.content_text,
					files : this.files,
					gallery : this.gallery
				}
			})
		} ,
		handleMore() {
			this.showMore = !this.showMore
		} ,
		checkTicketFile(file) {
			const type = file.type
			if (!isStringEmpty(type) && (type.indexOf('video/') === 0 || type.indexOf('image/') ===0))
				return false
			else
				return true
		} ,
		clear() {
			this.content = ''
			this.content_text = ''
			this.files = []
			this.gallery = []
			this.$refs.fileUploader.clear()
			this.$refs.imageUploader.clear()
			this.showMore = false
		}
	}

}
</script>

<style lang="less">
.comment-input-more {
	text-decoration: underline !important;
	text-decoration-style: dashed;
	color : @label-color;
	display : block;
	margin-top : 8px;
}
.comment-input-files {
	margin-top : 12px;
}
.comment-input-data {
	border : 1px dashed @border-color-base;
	padding : 8px 18px 18px;
	margin-top : 8px;
	.title {
		font-size : 1.1em;
		color : @label-color;
	}
}
</style>
