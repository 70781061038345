<i18n locale="th" lang="yaml" src="@i18n/ticket/ticket.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
ticket_detail.field.drone : "Drone"
ticket_detail.field.company : "ศูนย์"
ticket_detail.field.drone.name : "{serialNo} ({name})"
ticket_detail.field.drone.model : "รุ่น"

ticket_detail.field.link_services : "อ้างอิงใบซ่อม"
ticket_detail.field.status : "สถานะ"
ticket_detail.field.status.ticket : "Ticket"
</i18n>

<template>
	<div v-if="isValid" class="ticket-detail-container">
		<div class="ticket-col">
			<label>{{$t('ticket.field.ticket_no')}}</label>
			<div class="ticket-val">
				<div class="ticket-val-row ticket-field-ticket_no">
					{{ticket.ticket_no}}
				</div>
				<div class="ticket-val-row visible-tablet">
					(
						{{$t('ticket_detail.field.company')}}
						{{ticket.reportByData.company.name}}
					)
				</div>
			</div>
		</div>
		<div class="ticket-col">
			<label>{{$t('ticket_detail.field.drone')}}</label>
			<div class="ticket-val">
				<template v-if="isGeneralTicket">
					{{$t('ticket.field.general_ticket')}}
				</template>
				<template v-else>
					<div class="ticket-val-row" >
						<my-router-link :clickable="linkClickable" name="drone/view" :param-value="droneData.drone.serial_no" param-name="serial_no">
							{{$t('ticket_detail.field.drone.name',{
									name: droneData.drone.name ,
									serialNo: droneData.drone.serial_no,
								})
							}}
						</my-router-link>
					</div>
					<div class="ticket-val-row">
						{{$t('ticket_detail.field.drone.model')}}
						<my-router-link :clickable="linkClickable" name="drone_model/view" :param-value="droneData.model.id" auth-action="read" auth-module="droneModel">
							{{droneData.model.name}}
						</my-router-link>
						/
						<my-router-link v-trim :clickable="linkClickable" name="drone_lot/view" :param-value="droneData.lot.id">
							{{droneData.lot.lot_name}}
						</my-router-link>
					</div>
				</template>
			</div>
		</div>
		<div class="ticket-col hidden-tablet">
			<label>{{$t('ticket_detail.field.company')}}</label>
			<div class="ticket-val">
				<ImageName :name="company.name" :src="company.image_url">
					<a-icon type="shop" />
				</ImageName>
			</div>
		</div>
		<div class="ticket-col">
			<label>{{$t('ticket.field.ticket_types')}}</label>
			<div class="ticket-val">
				{{cTicketTypes}}
			</div>
		</div>
		<div class="ticket-col">
			<label>{{$t('ticket.field.event_datetime')}}</label>
			<div class="ticket-val">
				{{$dayjs(ticket.event_datetime).format('LLL')}}
			</div>
		</div>
		<div class="ticket-col">
			<label>{{$t('ticket.field.location_type')}}</label>
			<div class="ticket-val">
				{{$tenum('ticket.location_type',ticket.location_type)}}
			</div>
		</div>
		<div class="ticket-col">
			<label>{{$t('ticket_detail.field.link_services')}}</label>
			<div class="ticket-val">
				<span v-if="!$notEmpty(linkServices)">-</span>
				<template v-else>
					<div v-for="linkService of linkServices" :key="linkService.id" class="ticket-link-service ticket-val-row">
						<my-router-link v-if="checkServiceViewable(linkService.serviceData) && linkClickable" name="service/view" :param-value="linkService.serviceData.id">
							{{linkService.serviceData.service_no}}
						</my-router-link>
						<span v-else>
							{{linkService.serviceData.service_no}}
						</span>
					</div>
				</template>
			</div>
		</div>
		<div class="ticket-col">
			<label>{{$t('ticket.field.problem_type')}}</label>
			<div class="ticket-val">
				{{$tenum('ticket.problem_type',ticket.problem_type)}}
			</div>
		</div>
		<div class="ticket-col">
			<label>{{$t('ticket.field.search_tags')}}</label>
			<div class="ticket-val">
				<MyTagsInput read-only
					tag-css-class="mytag-info"
					:value="ticket.search_tags"/>
			</div>
		</div>

		<div class="ticket-col">
			<label>{{$t('ticket_detail.field.status')}}</label>
			<div class="ticket-val">
				<div class="ticket-val-row ticket-field-status">
					<label v-if="hasResult">{{$t('ticket_detail.field.status.ticket')}}</label>
					<TicketStatus
						:status="ticket.status"
						:reason="ticket.status_reason"
						:tag-style="false"/>

				</div>
				<div v-if="hasResult" class="ticket-val-row ticket-field-status">
					<label>{{$t('ticket.field.resolved_result')}}</label>
					<span>{{$tenum('ticket.resolved_result',ticket.resolved_result)}}</span>
				</div>
			</div>
		</div>
		<div class="ticket-col">
			<label>{{$t('ticket.field.report_by')}}</label>
			<div class="ticket-val">
				<div class="ticket-val-row">
					<my-router-link :clickable="linkClickable" name="user/view" :param-value="ticket.reportByData.user.id"
					:new-window="true">
						<ImageName :name="ticket.reportByData.user.username" :src="ticket.reportByData.user.image_url">
							<a-icon type="user" />
						</ImageName>
					</my-router-link>
				</div>
				<div class="ticket-val-row">
					({{$dayjs(ticket.created_datetime).format('LLL')}})
				</div>
			</div>
		</div>
		<div class="ticket-col">
			<label>{{$t('ticket.field.updated')}}</label>
			<div class="ticket-val">
				<div class="ticket-val-row">
					<my-router-link :clickable="linkClickable" name="user/view" :param-value="ticket.updatedByData.user.id"
					:new-window="true">
						<ImageName :name="ticket.updatedByData.user.username" :src="ticket.updatedByData.user.image_url">
							<a-icon type="user" />
						</ImageName>
					</my-router-link>
				</div>
				<div class="ticket-val-row">
					({{$dayjs(ticket.updated_datetime).format('LLL')}})
				</div>
			</div>
		</div>
		<div class="ticket-col col-span-3 ">
			<label>{{$t('ticket.field.topic')}}</label>
			<div v-trim class="ticket-val multiline ticket-field-topic">
				{{ticket.topic}}
			</div>
		</div>
	</div>
</template>

<script>
import ImageName from "@components/common/ImageName.vue"
import MyTagsInput from "@components/input/MyTagsInput.vue"
import TicketStatus from "@components/ticket/TicketStatus.vue"
import {isStatusAllowView} from "@utils/serviceUtil"
export default {
	components : {
		ImageName, MyTagsInput ,
		TicketStatus,
	} ,
	props : {
		ticket : {
			type : null,
			default : () => []
		} ,
		linkServices : {
			type : null,
			default : () => []
		} ,
		linkClickable :{
			type : Boolean,
			default : true,
		} ,
	} ,
	computed : {
		isValid() {
			return this.ticket.id > 0
		},
		isGeneralTicket() {
			return !this.ticket.drone_id
		} ,
		hasResult() {
			return this.ticket.problem_type == 'consult' && this.$notEmpty(this.ticket.resolved_result)
		} ,

		company() {
			return this.ticket.reportByData.company
		} ,
		droneData() {
			return this.ticket.droneData
		} ,
		cTicketTypes() {
			return (this.ticket.ticketTypes) ? this.ticket.ticketTypes.map((type) => {
					return this.$tenum('ticket.ticket_type',type,type)
			}).join(', ') : null
		} ,
	} ,
	methods : {
		checkServiceViewable(service) {
			if (this.$authorize('read','service',{companyId : service.company_id}))
				return true
			else
				return isStatusAllowView('details',service.status)
		}
	}
}
</script>

<style lang="less" scoped>
@my-screen-tablet-small : 800px;
@my-screen-tablet : 1280px;
.ticket-detail-container {
	position: relative;
	display : flex;
	flex-wrap: wrap;
	background-color : @white;
	margin-bottom : 24px;

	border-radius: @border-radius-base;
	box-shadow : @shadow-1-right;
}
.visible-tablet {
	display : none !important;
	@media (min-width : @my-screen-tablet-small) and (max-width : @my-screen-tablet - 1) {
		display : block !important;
	}
}
.hidden-tablet {
	@media (min-width : @my-screen-tablet-small) and (max-width : @my-screen-tablet - 1) {
		display : none  !important;
	}
}

.ticket-col {
	flex-basis: 33%;
	display : flex;
	flex-wrap: nowrap;
	min-width: 0;
	border-bottom : 1px solid @gray-4;
	> label {
		font-family: @font-family-title;
		color : @primary-color;
		min-width : 135px;
		flex-basis : 135px;
		background-color : @gray-3;
		padding : 6px 8px;
	}
	> .ticket-val {
		flex-grow: 1;
		padding : 6px 8px;
		min-width: 0;
		.ticket-val-row {
			margin-bottom : 2px;
			&:last-child {
				margin-bottom : 0;
			}
		}
	}
	&.col-span-2 {
		flex-basis: 66%;
	}
	&.col-span-3 {
		flex-basis: 100%;
	}
	@media(max-width : @my-screen-tablet-small - 1) {
		flex-basis: 100%;
		&.col-span-2 {
			flex-basis: 100%;
		}
	}
	@media (min-width : @my-screen-tablet-small) and (max-width : @my-screen-tablet - 1) {
		flex-basis: 50%;
		&.col-span-2 {
			flex-basis: 50%;
		}
		&.col-span-2-tablet {
			flex-basis: 100%;
		}
	}
}
.ticket-val-row.ticket-field-status {
	margin-bottom : 6px !important;
	label {
		font-family: @font-family-title;
		color : @primary-color;
		display : inline-block;
		width : 75px;
		&::after {
			content : ' :';
		}
	}
}
.ticket-field-ticket_no {
	font-family: @font-family-title;
	color : @primary-color;
	font-size : 16px;
}
.ticket-val.ticket-field-topic {
	min-height : 64px;
}

</style>
