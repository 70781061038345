<template>
	<div class="quill-viewer ql-editor" v-bind="$attrs"
		v-html="cContent"/>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
export default {
	props : {
		content : {
			type : String,
			default : undefined,
		} ,
		emptyMessage : {
			type : String,
			default : '',
		}
	} ,
	computed : {
		cContent() {
			if (this.$notEmpty(this.content))
				return this.content
			else
				return this.emptyMessage
		}
	}
}
</script>
<style lang="less" scoped>
	.quill-viewer.ql-editor::v-deep {
		font-size : 13px;
		.ql-size-small {
			font-size : 0.85em;
		}
		.ql-size-large {
			font-size : 1.25em;
		}
		.ql-size-huge {
			font-size : 1.75em;
		}
		ol,ul {
			padding-left : 0.5em;
		}
	}


</style>
