<i18n locale="th" lang="yaml" src="@i18n/service/service.th.yaml">
</i18n>
<i18n locale="th" lang="yaml" >
page.title : "งานซ่อม : {name}"

tab.pre_service : "รับเครื่อง"
tab.service_job : "งานซ่อม"
tab.pre_delivery : "ทดสอบก่อนคืน"
tab.payment : "ค่าใช้จ่าย"
tab.return : "ส่งคืน"
tab.drone_info : "ข้อมูล Drone"
tab.claim : "Claim"

service_view.drone_detail.show_more : "แสดงข้อมูล Drone"
service_view.update.label : "แก้ไขใบงาน"
service_view.forbidden.status : "ใบงานนี้ คุณยังไม่สามารถดูรายละเอียดได้ ต้องรอให้ใบงานปิดเรียบร้อยก่อน"
</i18n>

<template>
	<div class="page page-padding page-fit-mobile">
		<my-page-header :title="$t('page.title',{ name: service.service_no })" />

		<ServiceActionMenu
			v-if="canUpdate || canOpenClaimRequest"
			:service="service"
			:link-tickets="linkTickets"
			:other-details="otherDetails"
			:check-prevent-exit="checkPreventExit"
			@changeLinkTickets="handleChangeTickets"
			@changeOtherDetails="handleOtherDetails"
			@error="handleUpdateStatusError"/>

		<ErrorMessagePane
			:error="updateStatusError"/>

		<ServiceStatusProgress
			:service="service"/>

		<ServiceWarning
			:service="service"
			:other-details="otherDetails" />

		<ServiceDetail
			:service="service"
			:link-tickets="linkTickets"
			:other-details="otherDetails" />

		<div v-if="service.id > 0" class="service-container">
			<a-menu
				mode="horizontal"
				type="inner"
				class="mytab-menu service-tab-menu"
				:selected-keys="selectedMenu">
				<a-menu-item key="service/pre_service">
					<my-router-link name='service/pre_service' :param-value="$route.params.id">
						<PreServiceIcon class="anticon" /> {{$t('tab.pre_service')}}
					</my-router-link>
				</a-menu-item>
				<a-menu-item key="service/service_job">
					<my-router-link name='service/service_job' :param-value="$route.params.id">
						<ServiceIcon class="anticon" /> {{$t('tab.service_job')}}
					</my-router-link>
				</a-menu-item>
				<a-menu-item v-if="canViewPreDelivery" key="service/pre_delivery">
					<my-router-link name='service/pre_delivery' :param-value="$route.params.id">
						<PreDeliveryIcon class="anticon" /> {{$t('tab.pre_delivery')}}
					</my-router-link>
				</a-menu-item>
				<a-menu-item v-if="canViewClaim" key="service/claim">
					<my-router-link name='service/claim' :param-value="$route.params.id">
						<a-icon type="flag" /> {{$t('tab.claim')}}
					</my-router-link>
				</a-menu-item>
				<a-menu-item key="service/payment">
					<my-router-link v-if="showPayment" name='service/payment' :param-value="$route.params.id" :can-view="canViewPayment">
						<PaymentIcon class="anticon" /> {{$t('tab.payment')}}
					</my-router-link>
				</a-menu-item>
				<a-menu-item v-if="canViewReturn" key="service/return">
					<my-router-link name='service/return' :param-value="$route.params.id">
						<ReturnIcon class="anticon" /> {{$t('tab.return')}}
					</my-router-link>
				</a-menu-item>
			</a-menu>
			<div class="mytab-content">
				<router-view
					ref="serviceRef"
					:other-details="otherDetails"
					:link-tickets="linkTickets"
					:service="service"
					@changeLinkTickets="handleChangeTickets">
				</router-view>
			</div>
		</div>
	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import DeviceMixin from "@mixins/DeviceMixin.vue"
import ServiceObjectMixin from "@/src/mixins/service/ServiceObjectMixin.vue"
import axios from "axios"
import PaymentIcon from "vue-material-design-icons/CurrencyBtc.vue"
import ErrorMessagePane from "@components/common/ErrorMessagePane.vue"
import ServiceActionMenu from "@components/service/ServiceActionMenu.vue"
import ServiceDetail from "@components/service/ServiceDetail.vue"
import ServiceStatusProgress from "@components/service/ServiceStatusProgress.vue"
import ServiceWarning from "@components/service/ServiceWarning.vue"
import PreServiceIcon from "vue-material-design-icons/PackageVariant.vue"
import ServiceIcon from "vue-material-design-icons/HammerScrewdriver.vue"
import PreDeliveryIcon from "vue-material-design-icons/OrderBoolAscendingVariant.vue"
import ReturnIcon from "vue-material-design-icons/PackageVariantClosed.vue"
import {Menu} from "ant-design-vue"

export default {
	components : {
		"a-menu" : Menu, "a-menu-item" : Menu.Item ,
		ServiceActionMenu , ErrorMessagePane ,
		ServiceDetail, ServiceStatusProgress, ServiceWarning,
		PreServiceIcon, ServiceIcon, PreDeliveryIcon, ReturnIcon,
		PaymentIcon,
	} ,
	mixins : [PageMixin,DeviceMixin,ServiceObjectMixin] ,
	page() {
		return {
			title : this.$t('page.title',{name:this.service.service_no})
		}
	} ,
	data() {
		return {
			service : {} ,
			linkTickets : [],
			otherDetails : {} ,

			showDetail : true,
			selectedMenu : [] ,

			updateStatusError : undefined,
		}
	} ,
	computed : {
		canViewClaim() {
			return (
				this.canServiceClaim &&
				this.$authorize('read','claim') &&
				'claim_request' in this.otherDetails
			)
		} ,
		canOpenClaimRequest() {
			return this.canServiceClaim &&
				this.$authorize('create','claim',{companyId:this.service.company_id}) &&
				!('claim_request' in this.otherDetails)
		} ,
		showPayment() {
			return this.service.status !== 'new'
		}
	} ,
	watch : {
		$route(newVal) {
			if (!this.service || newVal.params.id != this.service.id) {
				this.fetchData()
			}
			this.updateStatusError = undefined
			this.updateSelectedMenu()
		} ,
		isDesktop() {
			this.showDetail = this.isDesktop
		} ,
	} ,

	mounted() {
		this.fetchData();
		this.updateSelectedMenu()
	} ,
	methods : {
		checkPreventExit() {
			if (this.$refs.serviceRef && 'checkPreventExit' in this.$refs.serviceRef) {
				return this.$refs.serviceRef.checkPreventExit()
			} else {
				return false
			}
		} ,
		updateSelectedMenu() {
			this.selectedMenu = [this.$route.name]
		} ,
		handleUpdateStatusError(error) {
			this.updateStatusError = error
		} ,
		fetchData() {
			const serviceId = this.$route.params.id
			if (!serviceId) {
				return
			}

			this.showPageLoading(true)
			this.clearBreadcrumbParams(['serviceNo','droneSerialNo'])
			axios.get("/api/services/"+serviceId).then((response)=>{
				this.service = response.data.data.service
				this.linkTickets = [...response.data.data.link_tickets]
				this.otherDetails = response.data.data.service.otherDetailsData

				delete this.service.otherDetailsData
				this.addBreadcrumbParams({
					serviceNo : this.service.service_no ,
					droneSerialNo : this.service.droneData.drone.serial_no
				})
			}).catch((error)=>{
				this.fetchError(error)
			}).finally(()=>{
				this.hidePageLoading()
			})
		} ,
		handleChangeTickets(payload) {
			if (payload.link_tickets) {
				this.linkTickets = [...payload.link_tickets]
			}
		} ,
		handleOtherDetails(details) {
			this.otherDetails = {...this.otherDetails,...details}
		}
	} ,
}
</script>

<style lang="less" scoped>
.service-update-btn {
	float : left;
	.mobile &{
		float : none;
		margin-top : 8px;
		text-align: left;
	}
}
.service-tab-menu::v-deep {
	.mobile & {
		line-height: 36px;
		.ant-menu-item {
			padding : 0 12px;
			line-height: 36px;
			font-size : 13.5px;
		}
	}
}
</style>
