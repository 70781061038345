<i18n locale="th" lang="yaml" >
uploader.upload.label : "Upload"
uploader.error.invalid_type : "ไม่รองรับ File ประเภทนี้ {type}"
uploader.error.exceed_size : "File มีขนาดเกินกว่า {max}"
</i18n>

<template>
	<div class="myuploader-container">
		<a-upload
			:action="uploadAction"
			list-type="picture-card"
			:multiple="true"
			:file-list="fileList"
			:before-upload="beforeUpload"
			:custom-request="uploadRequest"
			:preview-file="previewFile"
			:remove="removeUploadedFile"
			:disabled="viewOnly"
			:show-upload-list="{ showPreviewIcon: true,showRemoveIcon: !viewOnly }"
			@preview="previewUploadedFile"
			@change="handleChange">
			<div v-if="!viewOnly">
				<a-icon type="plus" />
				<div class="ant-upload-text">
					{{cUploadLabel}}
				</div>
			</div>
		</a-upload>
		<LightBox
			v-if="cfileMedias.length > 0"
			ref="lightbox"
			:show-light-box="false"
			:show-caption="true"
			:media="cfileMedias"/>
	</div>
</template>

<script>
import {Upload} from "ant-design-vue"
import LightBox from 'vue-image-lightbox'
import axios from "axios"
import {TIMEOUT_FILE_UPLOAD} from "@utils/axiosUtil"
import {previewImage as _previewImage} from "ant-design-vue/lib/upload/utils"
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css'
import Compressor from "compressorjs"
function isVideoFileType(type) {
	return !!type && type.indexOf('video/') === 0;
}
function isImageFileType(type) {
	return !!type && type.indexOf('image/') === 0;
}

export default {
	components : {
		"a-upload" : Upload, LightBox
	} ,
	props : {
		module : {
			type : String,
			default : 'tmp',
		} ,
		uploadAction : {
			type : String ,
			default : '/api/file/upload-tmp'
		} ,
		deleteAction : {
			type : String ,
			default : '/api/file/delete-tmp'
		} ,
		uploadLabel : {
			type : String,
			default : undefined
		} ,
		limitSize : {
			type : Number ,
			default : 40 * 1024 * 1024
		} ,
		maxImageWidth : {
			type : Number,
			default : 0
		} ,
		maxImageHeight : {
			type : Number,
			default : 0
		} ,
		viewOnly : {
			type : Boolean,
			default : false,
		}
	} ,
	data() {
		return {
			fileList : [] ,
			videoThumb : require("@assets/images/file_video.png") ,
			fileThumb : require("@assets/images/file_file.png") ,
		}
	} ,
	computed : {
		cUploadLabel() {
			return this.uploadLabel ? this.uploadLabel : this.$t('uploader.upload.label')
		} ,
		cfileMedias() {
			const list = []
			for(const fileData of this.fileList) {
				if (!this.isValidFileData(fileData)) {
					continue
				}
				if (fileData.response) {
					if (fileData.response.type == 'video') {
						// video
						list.push({
							thumb : this.videoThumb,
							sources : [
								{
									src : fileData.response.file_url ,
									type : fileData.response.content_type
								}
							],
							caption : fileData.name,
							type : 'video',
							autoplay : false,
						})
					} else {
						// image
						list.push({
							thumb : fileData.response.file_url ,
							src : fileData.response.file_url ,
							caption : fileData.name
						})
					}
				} else if (fileData.file_mode == 'initial') {
					if (isVideoFileType(fileData.type)) {
						list.push({
							thumb : this.videoThumb,
							sources : [
								{
									src : fileData.url ,
									type : fileData.type
								}
							],
							caption : fileData.name,
							type : 'video',
							autoplay : false,
						})
					} else {
						list.push({
							thumb : fileData.url ,
							src : fileData.url ,
							caption : fileData.name
						})
					}
				}
			}
			return list;
		}
	} ,
	methods : {
		setFileList(files) {
			for(const fileData of files) {
				const data = {
					uid : fileData.id ,
					url : fileData.file_url,
					name : fileData.filename,
					status : 'done',
					type : fileData.content_type,
					file_mode : 'initial' ,
					originalData : {...fileData}
				}

				if (isVideoFileType(fileData.content_type)) {
					data.thumbUrl = this.videoThumb
				} else if (!isImageFileType(fileData.content_type)) {
					data.thumbUrl = this.fileThumb
				}
				this.fileList.push(data)
			}
		} ,
		beforeUpload(file,fileList) {
			// Validate
			if (!isVideoFileType(file.type) && !isImageFileType(file.type)) {
				this.$message.error(this.$t('uploader.error.invalid_type',{type : file.type}))
				//
				const index = fileList.findIndex((checkFile) => file.uid == checkFile.uid)
				if (index >= 0) {
					fileList.splice(index,1);
				}
				return false
			}
			if (file.size > this.limitSize) {
				this.$message.error(this.$t('uploader.error.exceed_size',{max : this.limitSize}))
				const index = fileList.findIndex((checkFile) => file.uid == checkFile.uid)
				if (index >= 0) {
					fileList.splice(index,1);
				}
				return false;
			}
			return true;
		} ,
		uploadRequest(options) {
			// options {
			//  onProgress: (event: { percent: number }): void,
			//  onError: (event: Error, body?: Object): void,
			//  onSuccess: (body: Object): void,
			//  data: Object,
			//  filename: String,
			//  file: File,
			//  withCredentials: Boolean,
			//  action: String,
			//  headers: Object,
			// }
			const config = {
				onUploadProgress(event) {
					var percent = Math.round((event.loaded * 100) / event.total)
					options.onProgress({percent})
				} ,
				headers : {
					'content-type' : 'multipart/form-data'
				} ,
				timeout : TIMEOUT_FILE_UPLOAD
			}
			if (isImageFileType(options.file.type)) {
				/* eslint-disable no-unused-vars */
				const compressor = new Compressor(options.file,{
					quality : 0.8 ,
					maxWidth : this.maxImageWidth ,
					maxHeight : this.maxImageHeight ,
					success : (result) => {
						const formData = new FormData()
						formData.append('module',this.module)
						formData.append('file',result, result.name)

						axios.post(options.action,formData,config).then((response)=>{
							options.onSuccess(response.data.data.file)
							this.manualChange('add')
						}).catch((error)=>{
							options.onError(error)
						})
					} ,
					error(error) {
						options.onError(error)
					}
				});
			} else {
				const formData = new FormData()
				formData.append('module',this.module)
				formData.append('file',options.file)

				axios.post(options.action,formData,config).then((response)=>{
					options.onSuccess(response.data.data.file)
					this.manualChange('add')
				}).catch((error)=>{
					options.onError(error)
				})
			}
		} ,
		previewFile(file) {
			if (isImageFileType(file.type)) {
				return _previewImage(file)
			} else if (isVideoFileType(file.type)) {
				return Promise.resolve(this.videoThumb)
			} else {
				return Promise.resolve(this.fileThumb)
			}
		} ,
		removeUploadedFile(file) {
			if (file.response) {
				axios.delete(this.deleteAction,{data : {file : file.response.file_key}}).then((response)=>{});
			} else if (file.file_mode == 'initial') {
				this.$emit('remove_initial',{...file.originalData})
			}

			if(this.$refs.lightbox) {
				this.$refs.lightbox.showImage(0)
				this.$refs.lightbox.closeLightBox()
			}
			this.manualChange('remove',this._getEventFileList(file.uid))
			return true;
		} ,
		previewUploadedFile(file) {
			if (this.isValidFileData(file)) {
				const index = this.fileList.findIndex((checkFile) => file.uid == checkFile.uid)
				if (index >= 0 && this.$refs.lightbox) {
					this.$refs.lightbox.showImage(index);
				}
			}
		} ,
		isValidFileData(file) {
			return file.response || file.file_mode == 'initial'
		} ,
		manualChange(type,list=null) {
			const data = 	(list) || this._getEventFileList()
			this.$emit('change',{
				type : type,
				files : data ,
			});
		} ,
		_getEventFileList(ignoreUid = null) {
			return this.fileList.filter((f) => this.isValidFileData(f) && f.uid !== ignoreUid)
				.map((f) => {
					if (f.file_mode == 'initial')
						return {...f.originalData}
					else
						return {...f.response}
				})
		} ,
		handleChange({file,fileList,event}) {
			this.fileList = [...fileList];
		} ,
		clear() {
			this.fileList = []
			if (this.$refs.lightbox) {
				this.$refs.lightbox.showImage(0)
				this.$refs.lightbox.closeLightBox()
			}
		}
	}
}
</script>

<style lang="less">
.vue-lb-content {
	margin: 20%;
}
</style>
