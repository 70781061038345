<i18n locale="th" lang="yaml" >
processed.waiting : "รอประมวลผล"
processed.error : "ไม่สามารถตรวจสอบ Log ได้"
processed.done : "ดู Log"
</i18n>

<template>
	<div class="ticket-file-list">
		<div v-for="file in cFileList" :key="file.id" class="ticket-file">
			<MyTooltip trigger="hover" :title="file.original_filename" display-mode="white">
				<a @click="downloadFile(file)">
					<component :is="file.icon" /> {{file.filename}}
				</a>
			</MyTooltip>


			<my-router-link v-if="file.processed.id" name="ticket/log_file" :param-value="file.processed.id" new-window>
				<MyTooltip trigger="hover" :title="file.processed.message" display-mode="white">
					<a-icon :type="file.processed.icon" :class="file.processed.classes" />
				</MyTooltip>
			</my-router-link>
			<MyTooltip v-else-if="$notEmpty(file.processed.icon)" :title="file.processed.message" display-mode="white">
				<a-icon :type="file.processed.icon"  :class="file.processed.classes"/>
			</MyTooltip>
		</div>
	</div>
</template>

<script>
import axios from "axios"
import {TIMEOUT_FILE_DOWNLOAD} from "@utils/axiosUtil"
import fileDownload from 'js-file-download'
import ApiError from "@utils/errors/ApiError"
import MyTooltip from "@components/common/MyTooltip.vue"
import DroneIcon from "@components/icon/DroneIcon.vue"
import OthersIcon from "vue-material-design-icons/FileTableOutline.vue"
import PageMixin from "@mixins/PageMixin.vue"
export default {
	components : {
		MyTooltip
	} ,
	mixins : [PageMixin] ,
	props : {
		fileList : {
			type : Array,
			default : () => []
		} ,
		ticket : {
			type : null,
			default : () => []
		} ,
	} ,
	computed : {
		cFileList() {
			return this.fileList.map((file)=>{
				let filename = file.filename.toLowerCase()
				let icon = OthersIcon
				if (filename.endsWith('.bin')) {
					if (this.ticket.drone_id) {
						filename = this.ticket.droneData.drone.name+"_"+this.ticket.ticket_no+"_"+filename;
					} else {
						filename = "general_"+this.ticket.ticket_no+"_"+filename;
					}

					icon = DroneIcon
				}
				const processed = {}
				if (file.processed) {
					switch(file.processed.status) {
						case 'done' :
							processed.icon = "line-chart"
							processed.id = file.processed.id
							processed.message = this.$t('processed.done')
							processed.classes = "processed-status done"
							break
						case 'error' :
							processed.icon = "exclamation"
							processed.message = this.$t('processed.error')
							processed.classes = "processed-status error"
							break
						case 'new' :
							processed.icon = "hourglass"
							processed.message = this.$t('processed.waiting')
							processed.classes = "processed-status wait"
							break
					}

				}
				return {
					file_key : file.file_key,
					id : file.id,
					filename : filename,
					original_filename : file.filename,
					processed,
					icon
				}
			})
		}
	} ,
	methods : {
		downloadFile(file) {
			this.showPageLoading(true)
			axios.get("/api/file/download",{
				responseType: 'blob' ,
				params : {file : file.file_key} ,
				timeout : TIMEOUT_FILE_DOWNLOAD
			}).then((response) => {
				fileDownload(response.data,file.filename);
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error));
			}).finally(() => {
				this.hidePageLoading()
			})
		}
	}
}
</script>
<style lang="less" scoped>
.ticket-file-list {
	margin-top : 8px;
	.ticket-file {
		a {
			text-decoration: underline dashed;
		}
	}
	.processed-status {
		font-size : 1.2em;
		color : @info-color;
		padding: 0 8px;
    margin-left: 4px;
		&.done {
			color : @success-color;
		}
		&.error {
			color : @error-color;
		}
	}
}
</style>
