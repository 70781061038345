<template>
	<div v-if="visible" class="error-message-pane">
		<div class="message-icon">
			<a-icon type="close-circle" />
		</div>
		<div v-if=showDetail class="message-content">
			<div class="title">{{message}}</div>
			<div class="description">
				<ul v-if="detailMode == 'model'" class="model-description">
					<li v-for="model of modelErrors" :key="model.model_key">
						<span class="model-detail-title">
							{{model.model_name | emptyCheck(model.model_key)}}
						</span>
						<ul class="model-details">
							<li v-for='(field,attr) of model.field_errors' :key="attr" class="model-detail">
								{{field.join(', ')}}
							</li>
						</ul>
					</li>
				</ul>
				<ul v-if="detailMode == 'field'" class="field-description">
					<li v-for="(field,attr) of error.field_errors" :key="attr" class="field-detail">
						{{field.join(', ')}}
					</li>
				</ul>
				<ul v-if="detailMode == 'form'" class="model-description">
					<template v-if="!$notEmpty(onlyModelKeys)">
						<li v-for="(errorMessage,index) in error.generalMessages" :key="index" class="field-detail">
							{{errorMessage}}
						</li>
					</template>
					<li v-for="model of formModelErrors" :key="model.key">
						<span class="model-detail-title">
							{{model.name}}
						</span>
						<ul class="model-details">
							<li v-for='(errorMessage,index) in model.errors' :key="index" class="model-detail">
								{{errorMessage}}
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
		<div v-else class="message-content no-detail">
			<span class="title">{{message}}</span>
			<div v-if="!hideDebug && $notEmpty(debugMessage)" class="debug">{{debugMessage}}</div>
		</div>
	</div>
</template>

<script>
import _pick from "lodash/pick"
import ApiError from "@utils/errors/ApiError"
import ModelError from "@utils/errors/ModelError"
import FieldError from "@utils/errors/FieldError"
import FormError from "@utils/errors/FormError"
export default {
	props : {
		error : {
			type : null,
			default : undefined,
		} ,
		hideDebug : {
			type : Boolean,
			default : false
		} ,
		onlyModelKeys : {
			type : null,
			default : undefined,
		}
	} ,
	computed : {
		visible() {
			if (this.detailMode == 'model') {
				return this.$notEmpty(this.modelErrors)
			} else {
				return this.detailMode != ''
			}
		} ,
		message() {
			return this.error ? ApiError.getDisplayErrorMessage(this,this.error) : undefined
		} ,
		detailMode() {
			if (!this.error) {
				return ''
			} else if (this.error instanceof ModelError) {
				return 'model'
			} else if (this.error instanceof FieldError) {
				return 'field'
			} else if (this.error instanceof ApiError) {
				return 'api'
			} else if (this.error instanceof FormError) {
				return 'form'
			} else {
				return 'general'
			}
		} ,
		showDetail() {
			return this.detailMode == 'model' || this.detailMode == 'field' || this.detailMode == 'form'
		} ,
		modelErrors() {
			if (this.detailMode == 'model') {
				if (this.$notEmpty(this.onlyModelKeys)) {
					return this.error.model_errors.filter((m) => 'model_key' in m && this.onlyModelKeys.includes(m.model_key))
				} else {
					return this.error.model_errors
				}
			} else {
				return []
			}
		} ,
		formModelErrors() {
			if (this.detailMode == 'form') {
				if (this.$notEmpty(this.onlyModelKeys)) {
					return _pick(this.error.detailMessages,this.onlyModelKeys)
				} else {
					return this.error.detailMessages
				}
			} else {
				return []
			}
		} ,
		debugMessage() {
			return (this.error && 'getErrorTitle' in this.error) ? this.error.getErrorTitle() : undefined
		}
	} ,
}
</script>

<style lang="less" scoped>
.error-message-pane {
	display : flex;
	flex-wrap: nowrap;
	padding : 16px;
	background-color : @red-1;
	margin-bottom : 16px;
	border : 1px solid @error-color;
	border-radius : @border-radius-base;
	position : relative;

	.mobile & {
		font-size : 0.9em;
	}

	.message-icon {
		font-size : 2em;
		color : @error-color;
		padding-right : 12px;
		vertical-align: top;
		margin-top : -4px;
	}
	.message-content {
		flex-grow: 1;
		position : relative;
		.title {
			font-family : @font-family-title;
			font-size : 1.2em;
			color : @error-color;
		}
		.description {
			margin-top : 4px;
		}
		&.no-detail {
			margin-top : 4px;
			.mobile & {
				margin-top : 2px;
			}
		}
		.debug {
			position : absolute;
			bottom : -12px;
			text-align : right;
			right : 0;
			color : @text-muted;
			font-size : 0.8em;
		}

	}
	.model-description {
		list-style : none;
		margin-left : 12px;
		&:last-child {
			margin-bottom : 0;
		}
		.model-detail-title {
			text-decoration: underline;
		}
		.model-details {
			margin-left : 32px;
			font-size : 0.9em;
		}
	}

}

</style>
