<i18n locale="th" lang="yaml" >
mytags.add.label : "เพิ่ม"
</i18n>

<template>
	<div class="mytags-list">
		<a-tag v-for="tag in value" :key="tag" :closable="!readOnly" :class="tagCssClass" @close="handleClose($event,tag)">
			{{tag}}
		</a-tag>
		<template v-if="!readOnly">
			<a-input
				v-if="inputVisible"
				ref="input"
				type="text"
				size="small"
				:style="{ width: '80px' }"
				:value="inputValue"
				@change="handleInputChange"
				@blur="handleInputBlur"
				@keydown.tab="handleInputConfirm"
				@keydown.esc="handleInputBlur"
				@pressEnter="handleInputConfirm"/>
			<a-tag v-else ref="newTags" class="mytags-add"
				tabindex="0"
				@click="showInput" @focus="showInput">
				<a-icon type="plus" /> {{cAddLabel}}
			</a-tag>
		</template>
	</div>
</template>

<script>
import {Tag} from "ant-design-vue"
import { isStringEmpty } from '@utils/stringUtil'
export default {
	components : {
		"a-tag" : Tag ,
	} ,
	model : {
		prop: 'value',
    event: 'change'
	} ,
	props : {
		value : {
			type : Array,
			default : ()=>[]
		} ,
		addLabel : {
			type : String,
			default : undefined
		} ,
		tagCssClass : {
			type : String ,
			default : ''
		} ,
		readOnly : {
			type : Boolean ,
			default : false
		}
	} ,
	data() {
		return {
			inputVisible : false ,
			inputValue : '',
			fromEnter : false,
		}
	} ,
	computed : {
		cAddLabel(){
			return isStringEmpty(this.addLabel) ? this.$t('mytags.add.label') : this.addLabel
		}
	} ,
	methods : {
		showInput() {
			this.inputVisible = true
			this.$nextTick(function() {
        this.$refs.input.focus();
      });
		} ,
		handleClose(event,removedTag) {
			const tags = this.value.filter(tag => tag !== removedTag);
			this.$emit("change",tags)
		} ,
		handleInputChange(e) {
			this.inputValue = e.target.value;
		} ,
		handleInputBlur(e) {
			this.inputValue = ''
			if (this.fromEnter) {
				this.fromEnter = false
				this.$nextTick(() => {
					if (this.inputVisible)
						this.$refs.input.focus()
				})
			} else {
				this.inputVisible = false
			}
		} ,
		handleInputConfirm(e) {
			if (this.inputValue) {
				this.fromEnter = true
				if (this.value.indexOf(this.inputValue) === -1) {
					this.$emit('change',[...this.value,this.inputValue])
				}
				this.inputValue = ''
			}
			this.handleInputBlur()
			e.preventDefault()
		} ,
	}
}
</script>

<style lang="less" scoped>

.mytags-add {
	background : @white;
	border-style: dashed;
	min-width : 90px;
	text-align : center;
	cursor: pointer;
}
</style>
