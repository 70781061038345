<i18n locale="th" lang="yaml" >
uploader.upload.label : "Upload"
uploader.error.invalid_type : "ไม่รองรับ File ประเภทนี้ {type}"
uploader.error.exceed_size : "File มีขนาดเกินกว่า {max}"
</i18n>
<template>
	<div class="myuploader-container">
		<a-upload
			:action="uploadAction"
			:multiple="true"
			:file-list="fileList"
			:before-upload="beforeUpload"
			:custom-request="uploadRequest"
			:remove="removeUploadedFile"
			class="myupload"
			@change="handleChange">
			<a-icon type="upload" />{{cUploadLabel}}

		</a-upload>
	</div>
</template>
<script>
import {Upload} from "ant-design-vue"
import axios from "axios"
import {TIMEOUT_FILE_UPLOAD} from "@utils/axiosUtil"


export default {
	components : {
		"a-upload" : Upload,
	} ,
	props : {
		module : {
			type : String,
			default : 'tmp',
		} ,
		uploadAction : {
			type : String ,
			default : '/api/file/upload-tmp'
		} ,
		deleteAction : {
			type : String ,
			default : '/api/file/delete-tmp'
		} ,
		uploadLabel : {
			type : String,
			default : undefined
		} ,
		limitSize : {
			type : Number ,
			default : 40 * 1024 * 1024
		} ,
		checkSupportFile : {
			type : Function,
			default : undefined
		} ,
	} ,
	data() {
		return {
			fileList :  [] ,
			progressList : [] ,
			customFileList: [] ,
		}
	} ,
	computed : {
		cUploadLabel() {
			return this.uploadLabel ? this.uploadLabel : this.$t('uploader.upload.label')
		} ,
	} ,
	methods : {
		getCustomFileList() {
			return this.customFileList
		},
		addCustomFile(file) {
			this.customFileList.push(file)
			this.fileList.push(file)
		},
		beforeUpload(file,fileList) {
			// Validate
			if (this.checkSupportFile) {
				if (!this.checkSupportFile(file)) {
					this.$message.error(this.$t('uploader.error.invalid_type',{type : file.type}))
					//
					const index = fileList.findIndex((checkFile) => file.uid == checkFile.uid)
					if (index >= 0) {
						fileList.splice(index,1);
					}
					return false
				}
			}

			if (file.size > this.limitSize) {
				this.$message.error(this.$t('uploader.error.exceed_size',{max : this.limitSize}))
				const index = fileList.findIndex((checkFile) => file.uid == checkFile.uid)
				if (index >= 0) {
					fileList.splice(index,1);
				}
				return false;
			}
			this.handleProgress({file,state:'start'})
			return true;
		} ,
		uploadRequest(options) {
			// options {
			//  onProgress: (event: { percent: number }): void,
			//  onError: (event: Error, body?: Object): void,
			//  onSuccess: (body: Object): void,
			//  data: Object,
			//  filename: String,
			//  file: File,
			//  withCredentials: Boolean,
			//  action: String,
			//  headers: Object,
			// }
			const config = {
				onUploadProgress(event) {
					var percent = Math.round((event.loaded * 100) / event.total)
					options.onProgress({percent})
				} ,
				headers : {
					'content-type' : 'multipart/form-data'
				} ,
				timeout : TIMEOUT_FILE_UPLOAD
			}
			const formData = new FormData()
			formData.append('module',this.module)
			formData.append('file',options.file)
			axios.post(options.action,formData,config).then((response)=>{
				options.onSuccess(response.data.data.file)
				this.manualChange('add')
				this.handleProgress({file : options.file,state:'finish'})
			}).catch((error)=>{
				options.onError(error)
				this.handleProgress({file : options.file,state:'error'})
			})
		} ,
		removeUploadedFile(file) {
			if (this.customFileList && file.file_key ) {
				this.customFileList.forEach((files) => {
					if (file.file_key === files.file_key) {
						axios.delete(this.deleteAction,{data : {file : file.file_key}}).then((response)=>{});
						this.$emit('change',{
							type : 'remove',
							files : [file] ,
						})
					}
				})
			}
			else if (file.response) {
				axios.delete(this.deleteAction,{data : {file : file.response.file_key}}).then((response)=>{});
				this.manualChange('remove')
			}
			return true;
		} ,
		manualChange(type) {
			const data = 	this.fileList.filter((file) => file.response).map((file) => file.response)
			this.$emit('change',{
				type : type,
				files : data ,
			});
		} ,
		handleChange({file,fileList,event}) {
			this.fileList = [...fileList];
		} ,
		clear() {
			this.fileList = []
			this.progressList = []
		} ,
		handleProgress(payload) {
			const index = this.progressList.findIndex((f) => f == payload.file.uid)
			switch (payload.state) {
				case 'start' :
					if (index <= 0)
						this.progressList.push(payload.file.uid)
					break;
				default :
					if (index >= 0) {
						this.progressList.splice(index,1)
					}
					break;
			}
			this.$emit("upload-state",this.progressList.length > 0 ? 'busy' : 'idle')
			this.$emit("upload-progress",payload)
		}
	}
}
</script>
<style lang="less" scoped>
@import '~ant-design-vue/lib/button/style/mixin.less';
.myupload::v-deep {
	.ant-upload[role='button'] {
		line-height: @btn-height-base - 2px;
		.btn();
		.btn-default();
		> i {
			margin-right : 4px;
		}
	}
}
</style>
