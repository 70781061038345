<i18n locale="th" lang="yaml" >
log.company : "จากศูนย์"
log.updated_datetime : "เมื่อ"
log.not_valid : "ไม่มีข้อมูล"
log.reason : "เนื่องจาก : {reason}"
log.before_status : "สถานะก่อนหน้าคือ {status}"
</i18n>

<template>
	<div class="mycard-select-card with-image">
		<template v-if="isValid">
			<div class="col-image">
				<ImageLoader
					:src="user.image_file_url"
					class="mycard-image"
					size="tiny"
					border-type="circle">
					<a-icon type="user" />
				</ImageLoader>
			</div>
			<div class="col-content">
				<div v-if="$notEmpty(log.status_reason) || $notEmpty(log.before_status)" class="card-title">
					<div v-if="$notEmpty(log.status_reason)">{{$t('log.reason',{ reason: log.status_reason })}}</div>
					<div v-if="$notEmpty(log.before_status)" class="card-detail textmuted">
						{{$t('log.before_status',{ status: $tenum('service.status',log.before_status) })}}
					</div>
					<hr />
				</div>

				<div class="card-title">{{user.username}}</div>
				<div class="card-detail">
					<label>{{$t('log.company')}} : </label> {{company.name}}
				</div>

				<div v-if="$notEmpty(log.updated_datetime)" class="card-detail text-muted">
					<label>{{$t('log.updated_datetime')}} : </label> {{$dayjs(log.updated_datetime).format('LLL')}}
				</div>
			</div>
		</template>
		<div v-else class="col-content">
			{{$t('log.not_valid')}}
		</div>
	</div>
</template>

<script>
import ImageLoader from "@components/common/ImageLoader.vue"
export default {
	components : {
		ImageLoader
	} ,
	props : {
		service : {
			type : null,
			default : []
		} ,
		log : {
			type : null,
			default : []
		}
	} ,
	computed : {
		isValid() {
			return this.log.id && this.log.id > 0
		} ,
		user() {
			if (this.log.updatedBy && this.log.updatedBy.id)
				return this.log.updatedBy.user
			else
				return {}
		} ,
		company() {
			if (this.log.updatedBy && this.log.updatedBy.id)
				return this.log.updatedBy.company
			else
				return {}
		}
	}
}
</script>
