<template>
	<MyTooltip v-if="$notEmpty(value)" class="cursor-pointer" display-mode="white">
		<span slot="title">
			<span v-if="$notEmpty(additionalTooltip)">
				{{additionalTooltip}} <br />
			</span>
			{{date}}
		</span>
		<span>
			{{prefix}} {{fromNow}}
		</span>
	</MyTooltip>
</template>

<script>
import MyTooltip from "@components/common/MyTooltip.vue"
export default {
	components : {
		MyTooltip
	} ,
	props : {
		value : {
			type : String,
			default : undefined,
		} ,
		hideTime : {
			type : Boolean,
			default : false,
		} ,
		prefix : {
			type : String,
			default : undefined,
		} ,
		additionalTooltip : {
			type : String,
			default : undefined
		}
	} ,
	computed : {
		fromNow() {
			return this.$dayjs(this.value).fromNow()
		} ,
		date() {
			if (this.hideTime) {
				return this.$dayjs(this.value).format("D MMMM YYYY")
			} else {
				return this.$dayjs(this.value).format("LLL")
			}
		}
	}
}
</script>
