<script>
import {emptyDrone} from "@utils/objectUtil"
import {isStatusAllowView as _isStatusAllowView} from "@/src/utils/serviceUtil"
export default {
	computed : {
		isServiceValid() {
			return this.service && this.service.id && this.service.id > 0
		} ,
		droneData() {
			return this.isServiceValid ? this.service.droneData : emptyDrone()
		} ,
		canServiceClaim() {
			return this.isServiceValid &&
				_isStatusAllowView('claim',this.service.status)
		} ,
		canViewDetail() {
			if (!this.isServiceValid)
				return false
			else if (this.$authorize('read','service'))
				return true
			else
				return _isStatusAllowView('details',this.service.status)
		} ,
		canViewPreDelivery() {
			return (
				this.isServiceValid &&
				_isStatusAllowView('pre_delivery',this.service.status)
			)
		} ,
		canViewPayment() {
			return (
				this.isServiceValid &&
				this.$authorize('read','service') &&
				_isStatusAllowView('payment',this.service.status)
			)
		} ,
		canViewReturn() {
			return (
				this.isServiceValid &&
				_isStatusAllowView('return',this.service.status)
			)
		} ,

		canUpdate() {
			return (
				this.isServiceValid &&
				this.$authorize('update','service',{companyId : this.service.company_id}) &&
				!(this.service.status == 'close' || this.service.status == 'cancel')
			)
		} ,
		canUpdatePayment() {
			return this.canViewPayment && this.canUpdate &&
				this.$authorize('update','service',{companyId : this.service.company_id})
		} ,
		canUpdatePreDelivery() {
			return this.canViewPreDelivery && this.canUpdate
		} ,
		canUpdateReturn() {
			return this.canViewReturn && this.canUpdate
		} ,
	} ,
}
</script>
